
import { defineComponent, ref } from "vue";
import store from "@/store";
import Cache from "@/utils/cache";

export default defineComponent({
    props: {
        userIndentity: {
            type: Array,
            required: true,
            default: [],
        },
        userInfo: {
            type: Object,
            required: true,
            default: {},
        },
        logoutLogin: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    setup(props: any, context) {
        let userInfo = Cache.getStorage(Cache.jicaiUser) || {};
        const dialogVisible = ref<any>(false);
        const indentInfo = ref<any>({});
        let merchantId = ref<any>(userInfo.merchantId||null);
        const title = ref<any>("身份切换");
        //身份选中事件
        const userTouch = (item) => {
            indentInfo.value = item;
            merchantId.value = item.merchantId;
        };
        //身份切换确定事件
        const userAdd = () => {
            if(JSON.stringify(indentInfo.value) == '{}'){
                dialogVisible.value = false;
                return;
            }
            context.emit("userAdd", indentInfo.value);
            dialogVisible.value = false;
        };
        const logout = () => {
            dialogVisible.value = false;
            if (props.logoutLogin) {
                store.dispatch("user/logout");
            }
        };
        return {
            dialogVisible,
            title,
            indentInfo,
            merchantId,
            userTouch,
            userAdd,
            logout,
        };
    },
});
